import Button from "../../ui/form/Button"
import { DownloadReportButtonProps } from "../../../types"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { downloadReport } from "../../../actions/reportsActions"
import { successFilledToastOptions } from "../../../constants"
import { toast } from "react-toastify"
import React, { useEffect, useState } from "react"
import Tooltip from "../../ui/Tooltip"
import { KButton } from "kahuna-base-react-components"
import { lang } from "../../../constants/languages"

const DownloadReportButton = (props: DownloadReportButtonProps) => {
  const [disabled, setDisabled] = useState(!props.isAdmin)
  const [tooltipContent, setTooltipContent] = useState(lang.reports.list_component.download_report_button_tooltip)
  const onClick = async () => {
    toast.success(lang.reports.list_component.success, successFilledToastOptions)
    setDisabled(true)
    setTooltipContent(lang.reports.list_component.download_report_button_updated_tooltip)

    if (!props.isAdmin) {
      await props.downloadReport(props.reportId)
    } else if (props.isAdmin && props.selectedUserId) {
      await props.downloadReport(props.reportId, props.selectedUserId, props.approveDisabled)
    }

    setDisabled(false)
    setTooltipContent(lang.reports.list_component.download_report_button_tooltip)
  }

  useEffect(() => {
    setTooltipContent(
      props.isAdmin
        ? lang.reports.list_component.download_report_button_tooltip
        : lang.reports.list_component.download_report_button_disabled_text
    )
  }, [props.isAdmin])

  return (
    <Tooltip place="bottom" content={tooltipContent}>
      <div className="w-[110px] flex flex-row justify-end">
        <KButton
          onClick={onClick}
          disabled={disabled}
          width="36px"
          height="36px"
          padding="1px 1px"
          icon="/mail-unread-line.svg"
          background="white"
        />
      </div>
    </Tooltip>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps, { downloadReport })(DownloadReportButton)
